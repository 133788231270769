const env='staging';
const baseUrl = 'https://api.danblaax.net';
const googleAnalytics = 'UA-165299923-2';
const sentryDSN = 'https://884069b126b74957b4a704fc423dbdea@o95413.ingest.sentry.io/1537811';
const useDevTools = false;
const secureCookie = true;
const apiVersion = 'v1';
const stripeApiKey = 'pk_test_cOlSkBbVJIUNdgBThzd5lrrt';
const sessionKey = 'danblaax_sid';
const featureFlags = {
	enableSignup: true,
	enableSaxpack: true
};
export {
	env,
	baseUrl,
	googleAnalytics,
	sentryDSN,
	secureCookie,
	useDevTools,
	apiVersion,
	stripeApiKey,
	sessionKey,
	featureFlags,
};